@import 'assets/styles/colors';
@import 'assets/styles/z-indices';

.title {
  margin: 0 0 1.5rem 0;
}

.row {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.field {
  flex: 1;
}

.input {
  width: auto;
}

.checkbox {
  display: flex;
}

.buttons {
  z-index: $z-modal;
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  padding-right: 1.25rem;
  background-color: $primary-white;
}

.backButton {
  margin-right: 1rem;
}

.modal {
  width: 60%;
}

.status {
  flex: 0;
}

.spinner {
  display: flex;
  justify-content: center;
  margin: 2rem;
}

.offersHeader {
  margin: 0;
}

.table {
  min-height: auto;
  height: auto;
  margin: 1.5rem 0 0 0;
}

.offers {
  margin: 2rem 0;
  border-top: 1px solid $secondary-gray;
  padding-top: 2rem;
}

.arrowDown {
  transform: rotate(180deg);
  margin-left: -1rem;
}

.arrow {
  padding: 0;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;

  &.left {
    margin-right: 2.5rem;
  }
}

.display {
  display: none;
}

.hidden {
  visibility: hidden;
}
