// Primary Colors
$primary-blue: #0583dc;
$primary-gray: #7b88a8;
$primary-black: black;
$primary-white: white;
$primary-green: #1fbb28;
$primary-red: #fc2947;

// Secondary Colors
$secondary-gray: #f3f3f3;
$secondary-blue: #f4f8ff;
$secondary-one: #ffab00;
$secondary-two: #ff5630;
$secondary-three: #ebf2ff;
$secondary-four: #e8e8e8;
$secondary-five: #f2f5f7;

$tertiary-blue: #ebf2ff;

// Shadow
$box-shadow: rgba(55, 125, 255, 0.06);

// Status Colors
$status-active-background: #e9f9ea;
$status-active-font: #1db229;

$status-inactive-background: #eeeeee;
$status-inactive-font: #4f4f4f;

$status-closed-background: #f5d5d0;
$status-closed-font: #f9312e;