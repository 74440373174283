@import 'assets/styles/colors';

.text {
  display: flex;
  font-weight: bold !important;
  white-space: normal;
  width: min-content;
  text-align: left;

  &:hover {
    cursor: pointer;
  }
}

.arrowIcon {
  margin-left: 0.5rem;
}

.button {
  width: 90%;
  align-self: center;
  margin: 1rem;
  margin-bottom: 0rem;
  position: sticky;
  bottom: 0;
}

.divider {
  border-bottom: 1px solid $secondary-four;
  margin: 1rem;
}

.selected {
  text-decoration: underline;
  text-decoration-color: $primary-blue;
}

.popover {
  border-bottom: 1rem solid $primary-white;
  border-radius: 15px;
}

.overflow {
  overflow: auto;
}

.popover::-webkit-scrollbar {
  width: 1rem;
  height: 100%;
}

.popover::-webkit-scrollbar-track-piece:start {
  background-color: $primary-blue;
  border-radius: 7px;
  border-right: 0.4rem solid white;
  border-left: 0.4rem solid white;
}

.popover::-webkit-scrollbar-track {
  background-color: $secondary-four;
  margin: 1rem;
  border-radius: 7px;
  border-right: 0.4rem solid white;
  border-left: 0.4rem solid white;
}

.popover::-webkit-scrollbar-thumb {
  background-color: $primary-blue;
  border-radius: 100px;
  border: 3px solid $primary-white;
  width: 10%;
}

.column {
  display: flex;
  flex-direction: column;
}
