@import 'assets/styles/z-indices';

.top {
  flex-direction: column;
  align-items: flex-start;
  z-index: $z-filters-1;
}

.left {
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: $z-filters-1;
}

.label {
  margin-right: 1rem;
  margin-left: 0.2rem;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  display: flex;
}
