@import 'assets/styles/colors';

.addButtonsContainer {
  margin: 1rem 0rem;
}

.heading {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.addButtons {
  margin-right: 1rem;
  background-color: $primary-black;
}

.addIcon {
  transform: rotate(45deg);
  margin-right: 0.5rem;
}

.buttonsContent {
  display: flex;
  align-items: center;
}

.actionButtons {
  text-align: end;
  margin-bottom: 1rem;
}

.saveButton {
  margin-left: 1rem;
}

.subtitle {
  white-space: break-spaces;
}
