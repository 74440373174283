@import 'assets/styles/colors';

.form {
  margin: 0rem 1rem;
}

.actionButtons {
  text-align: end;
  position: sticky;
  bottom: 0;
  padding-top: 1rem;
  background-color: white;
  display: flex;
  justify-content: space-between;
}

.submitButton {
  margin-left: 1rem;
}

.checkbox {
  display: flex;
}

.back {
  margin-right: 1rem;
}

.linkButton {
  border: 1px solid $secondary-four;
  padding: 0.875rem 1.063rem;
  background: $primary-white;
  box-shadow: 0px 12px 23px $box-shadow;
  width: 100%;
  display: flex;
  margin-bottom: 1.2rem;

  &:hover {
    cursor: pointer;
  }
}

.linkButtonText {
  text-decoration: underline;
  text-decoration-color: $primary-blue;
  margin: 0rem;
  color: $primary-gray;
  font-family: 'Lato';
}

.labelText {
  margin-left: 0.25rem;
}

.button {
  background-color: $primary-white;
  display: flex;
  justify-content: center;
  padding: 0rem 1rem;
  align-items: center;

  &:hover {
    background-color: $primary-white;
  }
}

.addButtonContent {
  display: flex;
  border: 1px solid $secondary-four;
  background: $primary-white;
  box-shadow: 0px 12px 23px $box-shadow;
  width: 100%;
  margin-bottom: 1.2rem;
}

.addButtonText {
  color: $primary-gray;
  font-family: 'Lato';
}

.associatedNamesContainer {
  margin-bottom: 1.2rem;
}

.associatedNames {
  display: flex;
  align-items: center;
}

.addNameButton {
  margin-left: 1rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
}

.addIcon {
  transform: rotate(45deg);
  display: flex;
}

.nameItem {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $secondary-four;
  margin-left: 1rem;
}

.lastItem {
  border-bottom: none;
}

.nameItemText {
  margin-left: 1rem;
}

.removeNameIcon {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  margin: 0rem;
  padding: 0rem;
  margin-right: 1rem;

  &:hover {
    cursor: pointer;
  }
}

.associatedNameInput {
  margin-left: 1rem;
}

.addAssociatedNameActions {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
}

.switch {
  margin-bottom: 1rem;
}

.locationsAndOffers {
  display: flex;
  align-items: center;
}

.icon {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  margin: 0rem;
  padding: 0rem;
  margin-right: 1rem;

  &:hover {
    cursor: pointer;
  }
}

.heading {
  margin-bottom: 0.5rem;
}

.modalWidth {
  width: fit-content;
  min-width: 40%;
}

.addButtons {
  margin-right: 1rem;
  background-color: $primary-black;
  margin-bottom: 1rem;
}

.buttonAddIcon {
  transform: rotate(45deg);
  margin-right: 0.5rem;
}

.buttonsContent {
  display: flex;
  align-items: center;
}

.divider {
  border-top: 1px solid $secondary-four;
}

.labelWidth {
  width: 6rem;
  white-space: break-spaces;
}

.statuslabelWidth {
  width: fit-content;
}

.label {
  font-size: 0.75rem;
  opacity: 0.6;
  margin-bottom: 0.25rem;
  display: inline-block;
}
