.container {
  margin: 3rem 0 !important;
}

.form {
  width: 40%;
}

.width {
  width: unset;
}
