@import 'assets/styles/colors';

.container {
  display: flex;
  flex-direction: column;
}

.spinner {
  display: flex;
  justify-content: center;
  margin: 2rem;
}

.location {
  display: flex;
}

.locationText {
  margin: 0rem 0.5rem;
}

.button {
  width: fit-content;
  align-self: flex-end;
}

.heading {
  margin-bottom: 0rem;
  margin-top: 1rem;
}

.panelSearch {
  width: 50%;
  margin-top: 1rem;
}

.search {
  background-color: $primary-white;
  border-radius: 1rem;

  &:focus {
    outline: none;
  }
}

.table {
  margin: 2rem 0rem !important;
  min-height: auto !important;
}

.actionButtons {
  text-align: end;
  position: sticky;
  bottom: 0;
  padding-top: 1rem;
  background-color: white;
}
