@import 'assets/styles/colors';

.button {
  display: flex;
  align-items: center;
  width: 100%;
  font-family: 'Lato';
  color: $primary-gray;
  border: 1px solid $secondary-four;
  padding: 0.875rem 1.063rem;
  background: $primary-white;
  box-shadow: 0px 12px 23px rgba(55, 125, 255, 0.06);
}

.error {
  border: 1px solid $secondary-two;
}

.black {
  color: $primary-black;
}

.disabled {
  background-color: $secondary-four;
}
