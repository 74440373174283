@import 'assets/styles/colors';
@import 'assets/styles/z-indices';
@import './TableScrollbar.module.scss';

.tableWrapper {
  flex: 1;
  white-space: nowrap;
  background-color: $secondary-five;
  border-radius: 1rem;
  overflow-x: auto;
  overflow-y: scroll;
  max-width: 100%;
}

.table {
  width: 100%;
  max-width: 100%;
  border-spacing: 0;
  text-align: center;
}

.thead {
  position: sticky;
  top: 0;
  background-color: $secondary-five;
  z-index: $z-thead;
}

.th {
  padding: 1rem;
}

.td {
  border-top: 0.2rem solid $secondary-five;
  border-bottom: 0.2rem solid $secondary-five;

  &:first-of-type {
    border-left: 0.5rem solid $secondary-five;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }

  &:last-of-type {
    border-right: 0.5rem solid $secondary-five;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    padding-right: 1rem;
  }
}

.tdBody {
  text-align: left;
  padding-left: 1rem;
  max-width: 7.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
}

.tr {
  background-color: $primary-white;

  &:last-of-type {
    border-bottom: 0.4rem solid $secondary-five;
  }
}

.bold {
  font-weight: bold;
  white-space: normal;
  text-align: left;
  width: fit-content;
}

.noData {
  width: 100%;
  margin: 2rem 0rem;
}
