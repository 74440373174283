@import 'assets/styles/colors';

.form {
  margin: 0rem 1rem;
}

.actionButtons {
  text-align: end;
  position: sticky;
  bottom: 0;
  padding-top: 1rem;
  background-color: white;
}

.back {
  margin-right: 1rem;
}

.submitButton {
  margin-left: 1.25rem;
}

.switch {
  margin-bottom: 1rem;
}

.checkbox {
  display: flex;
  margin-bottom: 0;
}

.datePicker {
  margin-bottom: 1rem;
  align-self: flex-start;
}

.locationsModalWidth {
  width: fit-content;
}

.linkButton {
  border: 1px solid $secondary-four;
  padding: 0.875rem 1.063rem;
  background: $primary-white;
  box-shadow: 0px 12px 23px rgba(55, 125, 255, 0.06);
  width: 100%;
  display: flex;
  margin-bottom: 1.2rem;

  &:hover {
    cursor: pointer;
  }
}

.linkButtonText {
  text-decoration: underline;
  text-decoration-color: $primary-blue;
  margin: 0rem;
  color: $primary-gray;
  font-family: 'Lato';
}

.labelText {
  font-size: 0.75rem;
  opacity: 0.6;
  margin-bottom: 0.25rem;
  display: inline-block;
}

.readOnlyButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  margin: 0rem;
  padding: 0rem;

  &:hover {
    cursor: pointer;
  }
}

.heading {
  margin-bottom: 0.5rem;
}

.subtitle {
  margin-bottom: 2rem;
  margin-top: 0rem;
  font-weight: bold;
}

.labelWidth {
  width: 6rem;
  white-space: break-spaces;
}

.statuslabelWidth {
  width: fit-content;
}

.disabledInput {
  background: #e8e8e8;
}

.enabledInput {
  background: white;
}

.row {
  display: flex;
  gap: 2rem;
  color: $primary-gray;
  align-items: center;
  margin-bottom: 1.25rem;
}

.field {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;
}

.label {
  width: 24%;
  white-space: break-spaces;
}

.behaviorSelect,
.timescaleSelect {
  min-width: 10rem;
  margin-bottom: 0;
}

.daySelect {
  flex: 1;
  margin-bottom: 0;
}

.behaviorValue,
.timescaleValue {
  width: 3rem;
}

.formControl {
  margin-bottom: 0;
}

.formControlSelect {
  margin-bottom: 0;
}


.addRuleLink {
  all: unset;
  color: $primary-blue;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    color: #036fbc;
    background: none;
  }
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.AudienceRules {
  max-height: 7rem;
  overflow-y: scroll;
}

.ruleConnectors {
  margin-top: 1.75rem;
  border-top: 1px solid lightgray;
  padding-top: 1.25rem;
  margin-bottom: 1.5rem;
}

.ruleButton {
  all: unset;
  color: $primary-blue;
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
  margin-bottom: 0.25rem;

  &:hover {
    color: #036fbc;
  }
}

.rulesContainer {
  display: flex;
  flex-direction: column;
}

.createAnotherRuleHeading {
  margin: 1rem 0rem;
}

.modalWidth {
  width: 40%;
  height: 80%;
  overflow: hidden;
}

.errorMessages {
  color: $secondary-two;
}
