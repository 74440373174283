@import 'assets/styles/colors';

.table {
  border-radius: 1rem;
  width: 100%;
  align-self: center;
  margin: 1rem 0rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.tableHeading {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: space-between;
}

.actions {
  display: flex;
}

.button {
  display: flex;
  margin-left: auto;
  margin-right: 1rem;
  align-items: center;
  padding: 0.8rem;
}

.addIcon {
  transform: rotate(45deg);
  display: flex;
}

.spinner {
  display: flex;
  justify-content: center;
  margin: 10rem;
}

.modalWidth {
  width: 40%;
}

.uploadLocationModalWidth {
  width: 35%;
}

.search {
  background-color: $primary-white;
  border-radius: 1rem;
  border: none;
}

.searchWidth {
  width: 40%;
}

.tableBody {
  max-width: 5.5rem;
}

.exportLocations {
  display: flex;
  font-size: 0.875rem;
}

.exportIcon {
  height: 16px;
  margin-right: 0.65rem;
}

.exportButton {
  border: 1px solid;
  padding: 0.75rem 2.5rem;
}
