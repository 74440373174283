@import 'assets/styles/colors';
@import 'assets/styles/z-indices';

.content,
.fixedContent {
  width: 14rem;
  transition: width 0.3s ease-in-out;
}

.content.collapsed,
.content.collapsed > .fixedContent {
  width: 3.2rem;
}

.fixedContent {
  z-index: $z-sidebar;
  position: fixed;
  overflow-x: hidden;
  left: 0;
  top: 0;
  bottom: 0;
  padding: 2rem 0;
  border-right: solid $secondary-four 1px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.headerImg {
  width: 2.2rem;
  margin-left: 0.5rem;
  margin-bottom: 2rem;
}

.spacer {
  flex: 1;
}
