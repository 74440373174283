.tableWrapper::-webkit-scrollbar {
  width: 1rem;
}

.tableWrapper::-webkit-scrollbar-track-piece {
  border-radius: 1px;
  background-clip: content-box;
  border-radius: 6px;
  border: solid 4px transparent;
}

.tableWrapper::-webkit-scrollbar-track-piece:start {
  background: $primary-blue;
  background-clip: content-box;
  border: solid 6px transparent;
}

.tableWrapper::-webkit-scrollbar-track-piece:decrement {
  background: $primary-blue;
  background-clip: content-box;
  border: solid 6px transparent;
}

.tableWrapper::-webkit-scrollbar-track {
  background-color: $secondary-four;
  margin: 1rem;
  border-radius: 7px;
  border-right: 0.4rem solid $secondary-five;
  border-left: 0.4rem solid $secondary-five;
  border-top: 0.4rem solid $secondary-five;
  border-bottom: 0.4rem solid $secondary-five;
}

.tableWrapper::-webkit-scrollbar-thumb {
  background-color: $primary-blue;
  border-radius: 100px;
  border: 3px solid $primary-white;
  width: 10%;
}
