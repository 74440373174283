@import 'assets/styles/colors';

.exportText {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.principalText {
  color: $primary-black;
  font-weight: 600;
  font-size: 1.25rem;
  margin-bottom: 0.15rem;
}

.secondaryText {
  width: 100%;
  color: gray;
}

.button {
  font-family: 'Lato';
  border: none;
  border-radius: 17px;
  cursor: pointer;
  transition: background-color 125ms linear;
  margin-left: 5px;
}
.exportCompletedIcon {
  height: 3.25rem;
}

.exportCompletedContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.exportCompleted {
  display: flex;
  align-items: center;
}

.downloadLink {
  all: unset;
  color: $primary-black;
  text-decoration: underline;
  cursor: pointer;
}

.closeButton {
  border: 1px solid;
  padding: 0.75rem 2.5rem;
  font-size: 0.875rem;
}

.link {
  margin-right: 3rem;
}
