.menu {
  position: relative;
}

.button {
  border: none;
  background: none;
  cursor: pointer;
}

.dropdown {
  margin-top: 15px;
  width: 14rem;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  border-radius: 15px;
  padding: 10px 10px;
  position: absolute;
  right: 0;
}

.item {
  display: block;
  width: 100%;
  border: none;
  background: none;
  text-align: left;
  padding: 16px 0 16px 11px;
  cursor: pointer;
  color: #7b88a8;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.itemActive {
  background: #fafbfc;
  border-radius: 15px;
}
