@import 'assets/styles/colors';

.badge {
  font-family: 'Lato';
  border: none;
  display: flex;
  border-radius: 1rem;
  align-self: center;
  padding: 0.2rem 0.5rem;
}

.text {
  margin: 0rem;
  padding: 0rem 0.2rem;
  width: max-content;
}

.icon {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  margin: 0rem;
  padding: 0rem;

  &:hover {
    cursor: pointer;
  }
}

.primary {
  background-color: $primary-blue;
  color: $primary-white;
}

.secondary {
  background: $secondary-five;
  color: $primary-gray;
}

.tertiary {
  background: $primary-white;
  color: $primary-gray;
}

.s {
  font-size: 0.75rem;
  font-weight: 500;
}

.m {
  font-size: 1rem;
  font-weight: 500;
}

.spinner {
  padding: 5rem 5rem;
}
