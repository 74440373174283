.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  flex: 1;
}

.controls {
  display: flex;
  justify-content: space-evenly;
}

.contentAndQuery {
  display: flex;
  flex-flow: row wrap-reverse;
  gap: 1rem;
  flex: 1;
  font-size: 0.9em;
}

.content {
  display: flex;
  flex-flow: column;
  gap: 1rem;
  flex: 1;
}

.query {
  white-space: pre-wrap;
  word-wrap: break-word;
  flex-basis: 20rem;
}
