@import 'assets/styles/colors';

.button {
  font-family: 'Lato';
  border: none;
  border-radius: 17px;
  cursor: pointer;
  transition: background-color 125ms linear;
}

.primary {
  background: $primary-blue;
  color: $primary-white;

  &:hover {
    background: $primary-black;
  }

  &:active {
    opacity: 0.8;
  }

  &.loading:hover {
    background: $primary-blue;
  }
}

.secondary {
  background: $primary-white;
  color: $primary-black;

  &:hover {
    background: $primary-black;
    color: $primary-white;
  }

  &:active {
    opacity: 0.8;
  }

  &.loading:hover {
    background: $primary-white;
  }
}

.underlined {
  background: none;
  color: $primary-blue;
  text-decoration: underline;

  &:hover {
    color: $primary-black;
  }

  &:active {
    opacity: 0.8;
  }
}

.minimal {
  background: none;
  color: $primary-blue;

  &:hover {
    opacity: 0.8;
  }
}

.small {
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0.5rem 1.481rem;
}

.large {
  font-size: 1rem;
  font-weight: 600;
  padding: 1.156rem 2.438rem;
}

.isFullWidth {
  width: 100%;
}

.disabled {
  &:hover {
    cursor: not-allowed;
  }
}
