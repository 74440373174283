@import 'assets/styles/colors';

.container {
  display: flex;
  flex-direction: column;
}

.info {
  display: flex;
  border-bottom: 1px solid $secondary-four;
}

.label {
  font-weight: bold;
}

.value {
  margin-left: 1rem;
}

.actionButtons {
  text-align: end;
  position: sticky;
  bottom: 0;
  padding-top: 1rem;
  background-color: white;
}

.button {
  width: fit-content;
  align-self: flex-end;
}
