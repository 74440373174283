@import 'assets/styles/colors';

.form {
  margin: 0rem 1rem;
}

.actionButtons {
  text-align: end;
  position: sticky;
  bottom: 0;
  padding-top: 1rem;
  background-color: white;
  box-sizing: content-box;
  padding-bottom: 0.3rem;
}

.clone {
  float: left;
  background: none;
  color: $primary-blue;
  border: 2px solid $primary-blue;
  &:hover {
    border-color: $primary-black;
  }
}

.primaryButton {
  margin-left: 1rem;
}

.switch {
  margin-bottom: 1rem;
}

.checkbox {
  display: flex;
}

.datePicker {
  margin-bottom: 1rem;
  align-self: flex-start;
}

.locationsModalWidth {
  width: fit-content;
}

.linkButton {
  border: 1px solid $secondary-four;
  padding: 0.875rem 1.063rem;
  background: $primary-white;
  box-shadow: 0px 12px 23px rgba(55, 125, 255, 0.06);
  width: 100%;
  display: flex;
  margin-bottom: 1.2rem;

  &:hover {
    cursor: pointer;
  }
}

.linkButtonText {
  text-decoration: underline;
  text-decoration-color: $primary-blue;
  margin: 0rem;
  color: $primary-gray;
  font-family: 'Lato';
}

.labelText {
  font-size: 0.75rem;
  opacity: 0.6;
  margin-bottom: 0.25rem;
  display: inline-block;
}

.readOnlyButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  margin: 0rem;
  padding: 0rem;

  &:hover {
    cursor: pointer;
  }
}

.heading {
  margin-bottom: 0.5rem;
}

.subtitle {
  margin-bottom: 2rem;
  margin-top: 0rem;
  font-weight: bold;
}

.labelWidth {
  width: 6rem;
  white-space: break-spaces;
}

.statuslabelWidth {
  width: fit-content;
}
.selectAudience {
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectAudienceList {
  width: 80%;
}

.btnAudienceList {
  width: 20%;
  padding-left: 0.3125rem;
}
