@import 'assets/styles/colors';

.sortBy {
  font-weight: bold;
  text-align: left;
  margin: 1rem;
  margin-bottom: 0.5rem;
}

.sortByOptions {
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
  display: flex;
  margin: 0.5rem 1rem;
  padding-left: 0.5rem;
  width: fit-content;
  height: fit-content;

  &:hover {
    cursor: pointer;
  }
}

.sortByOptionsText {
  margin: 0rem;
  display: flex;
  align-items: center;
}

.arrowUp {
  margin-left: 0.5rem;
}

.arrowDown {
  transform: rotate(180deg);
  margin-left: 0.5rem;
}
