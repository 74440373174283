@import 'assets/styles/colors';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.selectCell {
  display: flex;
  margin-left: 50px;
}

.spinner {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}

.button {
  margin: 1rem;
  margin-right: 0rem;
  width: fit-content;
  align-self: flex-end;
}

.heading {
  text-align: start;
  position: sticky;
  top: 0;
  padding-top: 1rem;
  background-color: white;
}

.headingText {
  margin-top: 0rem;
}

.actionButtons {
  text-align: end;
  position: sticky;
  bottom: 0;
  background-color: white;
}

.content {
  display: flex;
  flex-direction: column;
  height: 90%;
}