/* Page content */
$z-thead: 010;
$z-filters-0: 050;
$z-filters-1: 051;
$z-filters-2: 052;
$z-popover: 070;
$z-header: 099;

/* Sidebar */
$z-sidebar: 100;

/* Overlays */
$z-modal: 200;
