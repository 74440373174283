.form {
  margin: 0rem 1rem;
}

.actionButtons {
  text-align: end;
  position: sticky;
  bottom: 0;
  padding-top: 1rem;
  background-color: white;
}

.back {
  margin-right: 1rem;
}

.submitButton {
  margin-left: 1rem;
}

.submitButton {
  margin-left: 1rem;
}

.switch {
  margin-bottom: 1rem;
}

.labelWidth {
  width: 6rem;
  white-space: break-spaces;
}

.labelText {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.issuerName {
  margin-left: 4px;
}

.issuerNameVal {
  margin-left: 14px;
}

.disabledInput {
  background: #e8e8e8;
}

.enabledInput {
  background: white;
}
