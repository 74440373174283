@import 'assets/styles/colors';

.status {
    display: inline-block;
    padding: .5rem .75rem;
    border-radius: 1rem;
    font-weight: 600;
    position: relative;

    &.active {
        background-color: $status-active-background;
        color: $status-active-font;
    }

    &.inactive {
        background-color: $status-inactive-background;
        color: $status-inactive-font;
    }

    &.closed {
        background-color: $status-closed-background;
        color: $status-closed-font;
    }

    &.active::before,
    &.inactive::before,
    &.closed::before {
        content: '·';
        font-weight: 700;
        position: absolute;
        font-size: 2.625rem;
        line-height: .5rem;
    }
}

.label {
    font-weight: 600;
    padding-left: 1.05rem;
}