.label {
  font-size: 0.875rem;
  opacity: 0.6;
  margin-bottom: 0.25rem;
  display: inline-block;
}

.hideShowPassword {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 13px;
  right: 13px;
  cursor: pointer;
  opacity: 0.75;
}

.passwordContainer {
  position: relative;
}

.newPasswordInput {
  padding-right: 3rem;
}
