@import 'assets/styles/colors';

.flex {
  display: flex;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexCenter {
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.buttons {
  align-self: flex-end;
  margin-top: 1rem;
}

.button {
  display: flex;
  align-items: center;
}

.cancelButton {
  margin-right: 1rem;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 4rem;
}

.explanationText {
  white-space: normal;
  display: flex;
  text-align: center;
  margin-bottom: 0rem;
}

.contentText {
  white-space: normal;
  display: flex;
  text-align: center;
}

.response {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}

.image {
  margin-right: 0.2rem;
}

.inputFile {
  display: none;
}

.fileName {
  margin: 0rem;
  text-decoration: underline;
}

.heading {
  margin-bottom: 0.5rem;
}

.subtitle {
  margin-bottom: 0.5rem;
  margin-top: 0rem;
  font-weight: bold;
}

.body {
  margin-bottom: 2rem;
  margin-top: 0rem;
  white-space: normal;
}

.csv {
  margin-bottom: 0.5rem;
}

.csvText {
  color: $primary-blue;
}
