@import 'assets/styles/colors';
@import 'assets/styles/z-indices';

.panel {
  z-index: $z-popover;
  width: 10rem;
  max-height: 500px;
  background-color: $primary-white;
  box-shadow: 0px 23px 55px 14px rgba(188, 196, 218, 0.14);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
}
