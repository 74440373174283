@import 'assets/styles/colors';
@import 'assets/styles/z-indices';

.CalendarDay__selected_span {
  background: lighten($primary-blue, 30%);
  color: $primary-white;
  border: 1px solid $primary-blue;

  &:hover {
    background: $primary-blue;
    color: $primary-white;
    border: 1px solid $primary-blue;
  }
}

.CalendarDay__selected {
  background: $primary-blue;
  color: $primary-white;
  border: 1px solid $primary-blue;

  &:hover {
    background: $primary-black;
    color: $primary-white;
    border: 1px solid $primary-blue;
  }
}

.CalendarDay__hovered_span {
  background: $secondary-blue;
  border: 1px solid $primary-blue;
  color: $primary-black;

  &:hover {
    background: $primary-blue;
    border: 1px solid $primary-blue;
    color: $primary-white;
  }
}

button.DayPickerKeyboardShortcuts_buttonReset {
  display: none;
}

.DateInput_input {
  border-bottom: none;
  text-align: center;
  border: 1px solid $primary-blue;
  border-radius: 1rem;
  color: $primary-gray;
  font-size: 0.75rem;
  font-family: 'Lato';

  &::placeholder {
    color: #7b88a8;
  }

  &:focus {
    border: 1px solid $primary-blue;
    border-radius: 1rem;
  }
}

.DateInput__small {
  &:first-child {
    margin-right: 2rem;
  }

  &:last-child {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.DayPicker_focusRegion {
  z-index: $z-filters-2;
}

.DateRangePickerInput__disabled {
  background: none;
}

.DateInput__disabled {
  background: none;
}

.DateRangePicker_picker {
  transform: translate3d(0, 0, 200px);
}
