@import 'assets/styles/colors';

.link {
  text-decoration: none;
  color: $primary-blue;
  font-weight: 500;
  font-size: 1rem;
  line-height: 29px;
}

.contents {
  display: contents;
}

.underline {
  &:hover {
    text-decoration: underline;
  }
}
