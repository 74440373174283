@import 'assets/styles/colors';

.imageMarginLeft {
  margin: 0.5rem 0 0 6.7rem;
}
.imageMarginY {
  margin: 0.5rem 0 0.5rem 0;
}

.imagePreview {
  height: 3rem;
  color: $primary-gray;
}

.imageHint {
  color: $primary-gray;
}

.flex {
  display: flex;
}
