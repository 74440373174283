@import 'assets/styles/colors';

.exportText {
  display: flex;
  flex-direction: column;
}

.principalText {
  color: $primary-black;
  font-weight: 600;
  font-size: 1.25rem;
  margin-bottom: 0.25rem;
}

.exportFailedIcon {
  margin-right: 3rem;
  height: 3.5rem;
}

.exportFailedContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.exportFailed {
  display: flex;
  align-items: center;
}

.closeButton {
  border: 1px solid;
  padding: 0.75rem 2.5rem;
  font-size: 0.875rem;
  margin-right: 1rem;
}

.tryAgainButton {
  border: 1px solid;
  padding: 0.75rem 2.5rem;
  font-size: 0.875rem;
  background-color: $primary-blue;
  color: $primary-white;
}
