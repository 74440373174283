.heading {
  color: #323b4b;
  margin: 2rem 0rem;
}

.xxl {
  font-weight: bold;
  font-size: 4rem;
}

.xl {
  font-weight: bold;
  font-size: 3rem;
}

.l {
  font-weight: bold;
  font-size: 2.5rem;
}

.m {
  font-weight: 600;
  font-size: 1.375rem;
}

.s {
  font-weight: 600;
  font-size: 1.125rem;
}

.xs {
  font-weight: 600;
  font-size: 1rem;
}
