@import 'assets/styles/colors';

.exportBanner {
  border: none;
  height: 4rem;
  display: flex;
  border-radius: 2rem;
  font-family: 'Lato';
  align-items: center;
  background: $primary-white;
  color: $primary-gray;
  padding: 1.5rem 3rem;
  box-shadow: 0px 12px 23px rgba(55, 125, 255, 0.06);
  position: relative;
}

.bannerContainer {
  width: 100%;
  margin: 1rem 0rem;
  border-radius: 1rem;
  align-self: center;
}
