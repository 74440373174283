.container {
  margin-bottom: 1.2rem;
}

.select {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.selectWithLeftLabel {
  width: 80%;
}

.error {
  margin-bottom: 0.2rem;
}

.labelText {
  margin-left: 0.25rem;
  margin-right: 0.5rem;
}

.noMargin {
  margin-top: 0rem;
}

.m {
  font-size: 1rem;
}

.s {
  font-size: 0.875rem;
}

.xs {
  font-size: 0.75rem;
}

.top {
  display: flex;
  flex-direction: column;
}

.left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.disabled {
  cursor: not-allowed;
}