.avatar {
  display: inline-block;
  border-radius: 50%;
  background: #0583dc;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.xl {
  height: 73px;
  width: 73px;
  font-size: 22px;
}

.l {
  height: 47px;
  width: 47px;
  font-size: 16px;
}

.m {
  height: 42px;
  width: 42px;
  font-size: 14px;
}

.s {
  height: 38px;
  width: 38px;
  font-size: 14px;
}

.xs {
  height: 32px;
  width: 32px;
  font-size: 14px;
}

.image {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.hasImage {
  background: none;
}
