@import 'assets/styles/colors';

.table {
  border-radius: 1rem;
  width: 100%;
  align-self: center;
  margin: 1rem 0rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.tableHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.searchWidth {
  width: 40%;
}

.search {
  background-color: $primary-white;
  border-radius: 1rem;
  border: none;

  &:focus {
    outline: none;
  }
}

.spinner {
  display: flex;
  justify-content: center;
  margin: 10rem;
}

.modalWidth {
  width: 40%;
}

.button {
  display: flex;
  margin-left: auto;
  margin-right: 1rem;
  align-items: center;
  padding: 0.8rem;
}

.addIcon {
  transform: rotate(45deg);
  display: flex;
}

.tableBody {
  max-width: fit-content;
}
