@import 'assets/styles/colors';

.select {
  border-radius: 1rem;
  height: 2.6rem;
  align-self: center;
  display: flex;
  align-items: center;
  background-color: $secondary-five;
  width: 100%;
}

.buttonLabel {
  margin-left: 1rem;
}

.arrow {
  margin-right: 1rem;
  margin-left: auto;
  background: none;
  border: none;
  transform: rotate(180deg);
}

.button {
  width: 90%;
  align-self: center;
  margin: 1rem;
}

.container {
  margin: 1rem;
}

.dateSelect {
  width: 50%;
}

.formInputs {
  display: flex;
  margin: 1rem;
}

.valueInput {
  width: 70%;
}

.unitSelect {
  width: 50%;
  margin-bottom: 0rem;
}

.formControl {
  margin-bottom: 0rem;
}

.selectedTab {
  background: $primary-blue;
  color: $primary-white;
  font-family: 'Lato';
  border: none;
  border-radius: 17px;
  cursor: pointer;
  padding: 0.5rem 1rem;
  margin-right: 0.25rem;
}

.deselectedTab {
  background: #fff;
  color: $primary-blue;
  font-family: 'Lato';
  border: none;
  border-radius: 17px;
  cursor: pointer;
  padding: 0.5rem 1rem;
  margin-right: 0.25rem;

  &:hover {
    background: $secondary-five;
  }
}

.tabList {
  margin: 0.5rem 0 2rem 0;
}

.clearDatesButton {
  width: 20px;
  height: 20px;
  margin-left: 0.625rem;
  cursor: pointer;
}

.datesRangePickerContainer {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
