@import 'assets/styles/colors';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  overflow-y: auto;
}

.info {
  display: flex;
  border-bottom: 1px solid $secondary-four;
  align-items: center;

  &:last-child {
    border-bottom: none;
  }
}

.label {
  font-weight: bold;
}

.value {
  margin-left: 1rem;
  white-space: normal;
  word-break: break-all;
}

.button {
  margin: 1rem;
  margin-right: 0rem;
  width: fit-content;
  align-self: flex-end;
}

.heading {
  text-align: start;
  position: sticky;
  top: 0;
  padding-bottom: 1rem;
  padding-top: 1rem;
  background-color: white;
}

.headingText {
  margin: 0rem;
}

.actionButtons {
  text-align: end;
  position: sticky;
  bottom: 0;
  padding-top: 1rem;
  background-color: white;
}

.matchedMerchants {
  display: flex;
  flex-direction: column;
  margin-right: 0.5rem;
}

.matchedMerchantsBody {
  display: flex;
}
