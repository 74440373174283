@import 'assets/styles/colors';
@import 'assets/styles/z-indices';

.parentDialog {
  position: fixed;
  overflow-y: auto;
  z-index: $z-modal;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: $primary-black;
  opacity: 0.3;
}

.childDialog {
  display: inline-block;
  max-width: 80%;
  max-height: 80%;
  padding: 0rem 1.5rem;
  border-bottom: 1.5rem solid white;
  border-top: 1.5rem solid white;
  margin: 2rem;
  overflow-y: scroll;
  white-space: nowrap;
  text-align: left;
  vertical-align: middle;
  background-color: white;
  box-shadow: 0px 12px 23px rgba(55, 125, 255, 0.06);
  border-radius: 1rem;
  z-index: inherit;
}

.childDialog::-webkit-scrollbar {
  width: 1rem;
  height: 100%;
}

.childDialog::-webkit-scrollbar-track-piece:start {
  background-color: $primary-blue;
  border-radius: 7px;
  border-right: 0.4rem solid white;
  border-left: 0.4rem solid white;
}

.childDialog::-webkit-scrollbar-track {
  background-color: $secondary-four;
  margin: 1.5rem;
  border-radius: 7px;
  border-right: 0.4rem solid white;
  border-left: 0.4rem solid white;
}

.childDialog::-webkit-scrollbar-thumb {
  background-color: $primary-blue;
  border-radius: 100px;
  border: 3px solid $primary-white;
  width: 10%;
}

.enterTransition {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-duration: 300ms;
}

.noOpacity {
  opacity: 0;
}

.fullOpacity {
  opacity: 1;
}

.leaveTransition {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  transition-duration: 200ms;
}
