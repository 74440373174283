.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.body {
  margin: 1rem 0rem;
  white-space: normal;
}

.buttons {
  align-self: flex-end;
  margin-top: 1rem;
}

.button {
  margin-left: 1rem;
}
