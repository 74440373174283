@import 'assets/styles/colors';

.form {
  margin: 0rem 1rem;
}

.heading {
  margin-bottom: 2rem;
}

.switch {
  margin-bottom: 1rem;
}

.checkbox {
  display: flex;
}

.actionButtons {
  text-align: end;
  position: sticky;
  bottom: 0;
  padding-top: 1rem;
  background-color: white;
}

.submitButton {
  margin-left: 1rem;
}

.back {
  margin-right: 1rem;
}

.modalWidth {
  width: fit-content;
}

.days {
  width: 6rem;
}

.labelText {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.labelWidth {
  width: 6rem;
  white-space: break-spaces;
}

.presenterMidsContainer {
  margin-bottom: 1.2rem;
}

.presenterMids {
  display: flex;
  align-items: center;
}

.addPresenterButton {
  margin-left: 1rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
}

.addIcon {
  transform: rotate(45deg);
  display: flex;
}

.presenterMidText {
  margin-left: 1rem;
}

.prensenterMidItem {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $secondary-four;
  margin-left: 1rem;
}

.lastItem {
  border-bottom: none;
}

.removePresenterMidIcon {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  margin: 0rem;
  padding: 0rem;
  margin-right: 1rem;

  &:hover {
    cursor: pointer;
  }
}

.presenterMidsInput {
  margin-left: 1rem;
}

.addPresenterMidActions {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
}
