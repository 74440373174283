@import 'assets/styles/colors';

.form {
  margin: 0rem 1rem;
}

.actionButtons {
  text-align: end;
  position: sticky;
  bottom: 0;
  padding-top: 1rem;
  background-color: $primary-white;
}

.back {
  margin-right: 1rem;
}

.submitButton {
  margin-left: 1rem;
}

.switch {
  margin-bottom: 1rem;
}

.checkbox {
  display: flex;
}

.datePicker {
  margin-bottom: 1rem;
  align-self: flex-start;
}

.locationsModalWidth {
  width: fit-content;
}

.linkButton {
  border: 1px solid $secondary-four;
  padding: 0.875rem 1.063rem;
  background: $primary-white;
  box-shadow: 0px 12px 23px $box-shadow;
  width: 100%;
  display: flex;
  margin-bottom: 1.2rem;

  &:hover {
    cursor: pointer;
  }
}

.linkButtonText {
  text-decoration: underline;
  text-decoration-color: $primary-blue;
  margin: 0rem;
  color: $primary-gray;
  font-family: 'Lato';
}

.labelText {
  font-size: 0.75rem;
  opacity: 0.6;
  margin-bottom: 0.25rem;
  display: inline-block;
}

.readOnlyButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  margin: 0rem;
  padding: 0rem;

  &:hover {
    cursor: pointer;
  }
}

.heading {
  margin-bottom: 0.5rem;
}

.subtitle {
  margin-bottom: 2rem;
  margin-top: 0rem;
  font-weight: bold;
}

.labelWidth {
  width: 6rem;
  white-space: break-spaces;
}

.statuslabelWidth {
  width: fit-content;
}

.disabledInput {
  background: $secondary-four;
}

.enabledInput {
  background: $primary-white;
}

.goBackLink {
  all: unset;
  color: $primary-blue;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    color: #036fbc;
  }
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.audienceRule {
  all: unset;
  color: $primary-blue;
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
  margin-bottom: 0.25rem;

  &:hover {
    color: #036fbc;
    background: none;
  }
}

.ruleConnector {
  color: $primary-blue;
  font-weight: 600;
  margin: 0 0.25rem;
}

.rulesContainer {
  display: flex;
  margin-bottom: 1.2rem;
}

.audienceRuleLabel {
  color: $primary-gray;
  width: 6rem;
  margin: 0 0.5rem 0 0.25rem;
  white-space: break-spaces;
}

.noRules {
  display: flex;
  color: $primary-gray;
  align-items: center;
}

.modalWidth {
  width: 40%;
  height: 80%;
  overflow: hidden;
}
