@import 'assets/styles/colors';

.main {
  height: 100%;
  display: flex;
}

.left {
  flex: 0.4;
  background: $primary-blue;
  background-image: url(../../../assets/patterns/primary-pattern.png);
  display: flex;
  justify-content: center;
  align-items: center;
}

.right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form {
  width: 35%;
}
