.formControl {
  margin-bottom: 1.2rem;
}

.formControlWithError {
  margin-bottom: 0.2rem;
}

.formControlErrorMessage {
  margin-left: 6.5rem;
}
