@import 'assets/styles/z-indices';

.header {
  display: flex;
  justify-content: flex-end;
  z-index: $z-header;
}

.container {
  display: flex;
}

.avatar {
  margin-right: 0.563rem;
}

.userProfile {
  width: 40%;
}
