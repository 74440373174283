.container {
  height: 100%;
  display: flex;
}

.layout {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-image: url(../../../assets/patterns/secondary-pattern.png);
  background-size: cover;
  min-width: 0;
  padding: 0.5rem 2rem;

  &.routeIsFullscreen {
    padding: 0;
  }
}

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
}
