@import 'assets/styles/colors';

.select {
  border-radius: 1rem;
  height: 2.6rem;
  align-self: center;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  background-color: $secondary-five;
}

.daysWidth {
  width: 20%;
}

.arrow {
  margin-right: 1rem;
  margin-left: auto;
  background: none;
  border: none;
  transform: rotate(180deg);
}

.table {
  border-radius: 1rem;
  width: 100%;
  align-self: center;
  margin: 1rem 0rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.tableHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.button {
  display: flex;
  margin-left: auto;
  margin-right: 1rem;
  align-items: center;
  padding: 0.8rem;
}

.search {
  background-color: $primary-white;
  border-radius: 1rem;
  border: none;
}

.searchWidth {
  width: 40%;
}

.spinner {
  display: flex;
  justify-content: center;
  margin: 10rem;
}

.reportActions {
  display: flex;
}

.addIcon {
  transform: rotate(45deg);
  display: flex;
}

.actions {
  display: flex;
}

.modalWidth {
  width: 40%;
}

.merchantDataWidth {
  width: fit-content;
}

.confirmationModalWidth {
  width: 'fit-content';
}

.locationsModalWidth {
  width: 80%;
}

.tableBody {
  max-width: 7.2rem;
}
