@import 'assets/styles/colors';

.item {
  border-radius: 1rem;
  height: 3rem;
  width: calc(100% - 2rem);
  align-self: center;
  display: grid;
  grid-template-columns: 1.2rem 1fr;
  grid-gap: 1rem;
  height: fit-content;
  align-items: center;
  padding: 0.5rem;
  white-space: nowrap;

  &:hover {
    background-color: $secondary-five;
    cursor: pointer;
  }
}

.icon {
  width: 100%;
}

.selected {
  background-color: $secondary-five;
}

.title {
  margin: 0;
}
