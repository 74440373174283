@import 'assets/styles/colors';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.leftLabel {
  flex-direction: row;
  align-items: center;
}

.leftLabelText {
  margin: 0rem;
  margin-right: 0.5rem;
}

.isDisabled {
  background-color: $secondary-four !important;
  border-color: #f5f5f5;
  box-shadow: none;
  color: #7a7a7a;
}

.labelText {
  margin-left: 0.25rem;
  margin-top: 0rem;
}

.input {
  width: 100%;
  font-family: 'Lato';
  color: $primary-gray;
  border: 1px solid $secondary-four;
  padding: 0.875rem 1.063rem;
  background: $primary-white;
  box-shadow: 0px 12px 23px rgba(55, 125, 255, 0.06);

  &::placeholder {
    color: $primary-gray;
  }

  &:not(:read-only):focus {
    outline: 1px solid $primary-blue;
    color: $primary-black;
  }

  &:read-only {
    cursor: not-allowed;

    &:focus {
      outline: none;
    }
  }
}

.leftLabelInput {
  width: 75%;
}

.inputHasIconLeft {
  padding: 0.875rem 0 0.875rem 3.125rem;
}

// .inputHasIconRight {
// padding: 0.875rem 0.75rem;
// }

.icon {
  position: absolute;
  width: 1.25rem;
  top: 50%;
  transform: translate(-50%, -50%);
}

.hasError {
  border: 1px solid $secondary-two;
  outline: none;
  box-shadow: 0px 12px 23px rgba(255, 86, 48, 0.06);

  &:focus {
    outline: none;
  }
}

.m {
  font-size: 1rem;
}

.s {
  font-size: 0.875rem;
}

.xs {
  font-size: 0.75rem;
}

.button {
  background-color: $primary-white;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: $primary-white;
  }
}

.disabledButton {
  background-color: $secondary-four;

  &:hover {
    background-color: $secondary-four;
  }
}

.iconWithLabelTop {
  transform: translate(-50%);
}

.rightIcon {
  right: 3rem;
  padding-right: 0.5rem;
}

.leftIcon {
  left: 1.563rem;
}

.imageIcon {
  padding-right: 0.2rem;
}

.gray {
  color: $primary-gray;
}

.black {
  color: $primary-black;
}

.red {
  color: $primary-red;
}

.green {
  color: $primary-green;
}
