@import 'assets/styles/colors';

.container {
  margin-bottom: 1rem;
}

.img {
  border: 1px solid $secondary-four;
  border-radius: 0.5rem;
}

.primary {
  border: 1px solid $primary-blue;
}

.secondary {
  border: 1px solid $secondary-four;
}

.label {
  margin-left: 0.5rem;
}

.button {
  background: none;
  border: none;
  padding: 0rem;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
  }
}
