@import 'assets/styles/colors';

.summaryData {
  margin-bottom: 1rem;
  background-color: $secondary-five;
  border-radius: 15px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 1.5rem;
  gap: 2rem;
}

.summary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.8rem;
  background-color: $primary-white;
  border-radius: 15px;
  width: 100%;
  gap: 1rem;
  font-weight: bold;
  font-size: 1.063rem;
}

.summaryValue {
  font-size: 1.25rem;
}
