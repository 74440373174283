@import 'assets/styles/colors';
@import 'assets/styles/z-indices';

.heading {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-self: center;
  margin-top: 1rem;
}

.select {
  border-radius: 1rem;
  height: 2.6rem;
  align-self: center;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  background-color: $secondary-five;
}

.daysWidth {
  width: 20%;
}

.arrow {
  margin-right: 1rem;
  margin-left: auto;
  background: none;
  border: none;
  transform: rotate(180deg);
}

.table {
  border-radius: 1rem;
  width: 100%;
  align-self: center;
  margin: 1rem 0rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.tableHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.button {
  margin: 0rem 2rem;
  display: flex;
  margin-left: auto;
  margin-right: 1rem;
}

.search {
  border: none;
  box-shadow: none;

  &:focus {
    outline: none;
  }
}

.searchContainer {
  width: 35%;
  display: flex;
  background-color: $primary-white;
  border-radius: 1rem;
  border: 1px solid $secondary-five;
  box-shadow: 0px 12px 23px rgba(55, 125, 255, 0.06);

  &:focus {
    outline-color: $primary-blue;
  }
}

.searchIcon {
  background: none;
  background-color: $primary-white;
  border: none;
  display: flex;
  align-items: center;
  margin: 0rem;
  padding: 1rem;
  border-radius: 1rem;

  &:hover {
    cursor: pointer;
  }
}

.spinner {
  display: flex;
  justify-content: center;
  margin: 10rem;
}

.reportActions {
  display: flex;
}

.formControlSelect {
  margin-bottom: 0rem;
  width: 12rem;
  padding-left: 1rem;
}

.reviewStatusSelect {
  z-index: $z-filters-0;
  margin-bottom: 0rem;
}

.form {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.modalWidth {
  width: 40%;
  height: 80%;
  overflow: hidden;
}

.confirmationModal {
  width: fit-content;
}

.alignSpinner {
  align-items: center;
  text-align: center;
  width: 100%;
}
