@import 'assets/styles/colors';

.container {
  margin-bottom: 1.2rem;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.itemText {
  margin-left: 1rem;
}

.addValueButton {
  margin-left: 1rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  gap: 4px;
}

.item {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $secondary-four;
  margin-left: 1rem;
}

.removeIcon {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  margin: 0rem;
  padding: 0rem;
  margin-right: 1rem;

  &:hover {
    cursor: pointer;
  }
}

.lastItem {
  border-bottom: none;
}

.input {
  margin-left: 1rem;
}

.actions {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
}

.submitButton {
  margin-left: 1rem;
}
