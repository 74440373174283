@import 'assets/styles/colors';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.info {
  display: flex;
  border-bottom: 1px solid $secondary-four;

  &:last-child {
    border-bottom: none;
  }
}

.label {
  font-weight: bold;
}

.value {
  margin-left: 1rem;
}

.spinner {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}

.error {
  white-space: pre-wrap;
}

.button {
  margin: 1rem;
  margin-right: 0rem;
  width: fit-content;
  align-self: flex-end;
}

.heading {
  text-align: start;
  position: sticky;
  top: 0;
  padding-bottom: 1rem;
  padding-top: 1rem;
  background-color: white;
}

.headingText {
  margin-top: 0rem;
}

.actionButtons {
  text-align: end;
  position: sticky;
  bottom: 0;
  background-color: white;
}

.item {
  display: block;
  width: 100%;
  border: none;
  background: none;
  text-align: left;
  padding: 16px 0 16px 11px;
  border-radius: 1rem;
  height: 2.5rem;
  align-self: center;
  padding-left: 1rem;
  display: flex;
  align-items: center;

  &:hover {
    background-color: $secondary-five;
    cursor: pointer;
  }
}

.selected {
  background-color: $secondary-five;
}

.divider {
  border-bottom: 1px solid $secondary-five;
}

.panelSearch {
  margin-bottom: 1rem;
}

.search {
  background-color: $primary-white;
  border-radius: 1rem;
  border: 1px solid $primary-blue;

  &:focus {
    outline: none;
  }
}

.selectedMerchant {
  margin-left: 1rem;
}

.listContainer {
  height: 55%;
  overflow: scroll;
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.pagination {
  margin: 1rem 0rem;
}

.listContainer::-webkit-scrollbar {
  width: 1rem;
  height: 100%;
}

.listContainer::-webkit-scrollbar-track-piece:start {
  background-color: $primary-blue;
  border-radius: 7px;
  border-right: 0.4rem solid white;
  border-left: 0.4rem solid white;
}

.listContainer::-webkit-scrollbar-track {
  background-color: $secondary-four;
  margin: 1.5rem;
  border-radius: 7px;
  border-right: 0.4rem solid white;
  border-left: 0.4rem solid white;
}

.listContainer::-webkit-scrollbar-thumb {
  background-color: $primary-blue;
  border-radius: 100px;
  border: 3px solid $primary-white;
  width: 10%;
}
