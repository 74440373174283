@import 'assets/styles/colors';

.popover {
  width: 35%;
}

.select {
  border-radius: 1rem;
  height: 2.6rem;
  align-self: center;
  display: flex;
  align-items: center;
  background-color: $secondary-five;
  width: 100%;
}

.text {
  font-weight: bold !important;
  margin-left: 1rem;
}

.arrow {
  margin-right: 1rem;
  margin-left: auto;
  background: none;
  border: none;
  transform: rotate(180deg);
}

.search {
  background-color: $primary-white;
  border-radius: 1rem;
  border: 1px solid $primary-blue;

  &:focus {
    outline: none;
  }
}

.panelSearch {
  margin: 1rem;
}

.list {
  list-style-type: none;
  padding-left: 0rem;
  margin: 0rem;
  overflow: auto;
}

.list::-webkit-scrollbar {
  width: 1rem;
  height: 100%;
}

.list::-webkit-scrollbar-track-piece:start {
  background-color: $primary-blue;
  border-radius: 7px;
  border-right: 0.4rem solid white;
  border-left: 0.4rem solid white;
}

.list::-webkit-scrollbar-track {
  background-color: $secondary-four;
  margin: 1rem;
  border-radius: 7px;
  border-right: 0.4rem solid white;
  border-left: 0.4rem solid white;
}

.list::-webkit-scrollbar-thumb {
  background-color: $primary-blue;
  border-radius: 100px;
  border: 3px solid $primary-white;
  width: 10%;
}

.item {
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
  height: 2.6rem;
  display: flex;
  margin: 0.5rem 1rem;
  padding-left: 0.5rem;
  width: 90%;

  &:hover {
    background-color: $secondary-blue;
    cursor: pointer;
    border-radius: 1rem;
  }
}

.title {
  margin: 1rem 1.5rem;
}

.button {
  width: 90%;
  align-self: center;
  margin: 1rem;
}

.selectedFields {
  display: flex;
  flex-direction: row;
  margin: 0rem 0.5rem 1rem 0.5rem;
  flex-wrap: wrap;
}

.badge {
  margin: 0.2rem 0.5rem;
}

.clearAll {
  align-self: flex-start;
  margin-left: 0.5rem;
}

.clicked {
  background-color: $secondary-blue;
  border-radius: 1rem;
}
