.container {
  margin-bottom: 1.2rem;
}

.error {
  margin-bottom: 0.2rem;
}

.top {
  display: flex;
  flex-direction: column;
}

.left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.label {
  margin-left: 0.25rem;
  margin-right: 0.5rem;
}

.datePicker {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.datePickerWithLeftLabel {
  width: 80%;
}
