@import 'assets/styles/colors';
@import 'assets/styles/z-indices';

.tableWrapper {
  flex: 1;
  white-space: nowrap;
  background-color: $secondary-five;
  border-radius: 15px;
  overflow-x: auto;
  overflow-y: scroll;
  max-width: 100%;
}

.table {
  max-width: 100%;
  border-spacing: 0;
  text-align: center;
  width: 100%;
}

.tableWrapper::-webkit-scrollbar {
  width: 1rem;
}

.tableWrapper::-webkit-scrollbar-track-piece {
  border-radius: 1px;
  background-clip: content-box;
  border-radius: 6px;
  border: solid 4px transparent;
}

.tableWrapper::-webkit-scrollbar-track-piece:start {
  background: $primary-blue;
  background-clip: content-box;
  border: solid 6px transparent;
}

.tableWrapper::-webkit-scrollbar-track-piece:decrement {
  background: $primary-blue;
  background-clip: content-box;
  border: solid 6px transparent;
}

.tableWrapper::-webkit-scrollbar-track {
  background-color: $secondary-four;
  margin: 1rem;
  border-radius: 7px;
  border-right: 0.4rem solid $secondary-five;
  border-left: 0.4rem solid $secondary-five;
  border-top: 0.4rem solid $secondary-five;
  border-bottom: 0.4rem solid $secondary-five;
}

.tableWrapper::-webkit-scrollbar-thumb {
  background-color: $primary-blue;
  border-radius: 100px;
  border: 3px solid $primary-white;
  width: 10%;
}

.thead {
  position: sticky;
  top: 0;
  background-color: $secondary-five;
  z-index: $z-thead;
}

.th {
  padding: 1rem;
}

.td {
  border-top: 0.2rem solid $secondary-five;
  border-bottom: 0.2rem solid $secondary-five;

  &:first-of-type {
    border-left: 0.5rem solid $secondary-five;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }

  &:last-of-type {
    border-right: 0.5rem solid $secondary-five;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    padding-right: 1rem;
  }
}

.lastRow {
  border-bottom: 0.4rem solid $secondary-five;
}

.isSelect {
  text-transform: none;
  text-align: left;
}

.bold {
  font-weight: bold;
  white-space: normal;
  text-align: left;
  width: fit-content;
}

.cellButton {
  background: none;
  border: none;
  display: flex;
  padding: 0rem;

  &:hover {
    cursor: pointer;
  }
}

.selectCell {
  margin-left: 1rem;
  display: inline;
}

.tdSwitch {
  display: flex;
  align-items: center;
  justify-content: left;
}

.cellSwitch {
  margin-left: 1rem;
}

.cellButtonText {
  text-transform: uppercase;
  text-decoration: underline;
  text-decoration-color: $primary-gray;
}

.cellButtonTextDisabled {
  color: $primary-gray;
}

.noData {
  width: 100%;
  margin: 2rem 0rem;
}

.tdBody {
  text-align: left;
  padding-left: 1rem;
  max-width: 7.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
}

.tdBodySwitch {
  padding-left: 0.5rem;
}

.tr {
  background-color: $primary-white;
}

.isSelectHeader {
  padding-left: 1.5rem;
}
