@import 'assets/styles/colors';

.exportText {
  display: flex;
  flex-direction: column;
}

.principalText {
  color: $primary-black;
  font-weight: 600;
  font-size: 1.25rem;
  margin-bottom: 0.25rem;
}

.exportInProgressIcon {
  margin-right: 3rem;
  height: 5rem;
}

.cancelButton {
  border: 1px solid;
  padding: 0.75rem 2.5rem;
  font-size: 0.875rem;
}

.exportInProgressContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.exportInProgress {
  display: flex;
  align-items: center;
}
