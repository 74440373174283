@import 'assets/styles/colors';

.pagination {
  display: flex;
  flex-direction: row;
  background-color: $secondary-five;
  height: 3.5rem;
  margin-top: 1rem;
  border-radius: 15px;
  justify-content: space-between;
  align-items: center;
}

.paginationInfo {
  margin-left: 1.75rem;
}

.paginationButtons {
  display: flex;
  flex-direction: row;
}

.icon {
  background-color: $primary-blue;
  border: none;
  width: 2.5rem;
  height: 2.5rem;
  align-items: center;
  border-radius: 10px;
  margin: 0rem 0.625rem;

  &:hover {
    cursor: pointer;
  }
}

.disabled {
  background-color: $secondary-four;

  &:hover {
    cursor: not-allowed;
  }
}

.lastIcon {
  margin-right: 1rem;
}

.pageNumber {
  color: $primary-gray;
  font-weight: bold;
  align-self: center;
  border: none;
  background: none;
  margin: 0rem 0.625rem;

  &:hover {
    cursor: pointer;
  }
}

.selectedPage {
  color: $primary-blue;
}

.paginationElipsis {
  color: $primary-gray;
  margin: 0rem 0.625rem;
  align-self: center;
}

.iconRight {
  transform: rotate(180deg);
}

.labelPageNumber {
  color: $primary-blue;
  font-size: 14px;
  font-weight: bold;
  align-self: center;
  border: none;
  background: none;
  margin: 0rem 0.625rem;

  &:hover {
    cursor: pointer;
  }
}

.selectStyle {
  background-color: white;
  border-radius: 4px;
  border-color: $primary-gray;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;

  margin-right: 1rem;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  background-image: linear-gradient(45deg, transparent 50%, $primary-blue 50%),
    linear-gradient(135deg, $primary-blue 50%, transparent 50%),
    linear-gradient(to right, #b5daf5, #b5daf5);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
}
