@import 'assets/styles/colors';

.collapseButton {
  background-color: $primary-blue;
  border: none;
  width: 2.2rem;
  height: 2.2rem;
  align-items: center;
  border-radius: 0.5rem;
  margin: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  &:hover {
    cursor: pointer;
  }
}
