@import 'assets/styles/colors';

.black {
  color: $primary-black;
}

.gray {
  color: $primary-gray;
}

.white {
  color: $primary-white;
}

.blue {
  color: $primary-blue;
}

.red {
  color: $secondary-two;
}

.m {
  font-weight: 500;
  font-size: 1.125rem;
}

.s {
  font-weight: 500;
  font-size: 1rem;
}

.xs {
  font-weight: 500;
  font-size: 0.85rem;
}

.xxs {
  font-weight: 500;
  font-size: 0.75rem;
}
