@import 'assets/styles/colors';

.group {
  display: flex;
  align-items: center;
}

.container {
  display: flex;
  align-items: center;
}

.label {
  margin: 0;
  margin-right: 0.5rem;
  margin-left: 0.25rem;
}

.leftLabel {
  margin: 0;
  margin-right: 0.5rem;
}

.topLabel {
  flex-direction: column;

  .label {
    margin: 0 0 1rem 0;
  }
}

.rightLabel {
  margin-left: 0.5rem;
}

.primaryEnabledSwitch {
  background-color: $primary-green;
}

.primaryDisabledSwitch {
  background-color: $primary-gray;
}

.secondaryEnabledSwitch {
  background-color: $primary-green;
}

.secondaryDisabledSwitch {
  background-color: $primary-gray;
}

.switch {
  position: relative;
  display: inline-flex;
  flex-shrink: 0;
  height: 1.5rem;
  width: 2.5rem;
  border: 2px solid transparent;
  outline-offset: 2px;
  border-radius: 1rem;
  padding: 0.2rem;
  align-items: center;

  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;

  &:hover {
    cursor: pointer;
  }
}

.primaryEnabledCircle {
  transform: translateX(1rem);
}

.primaryDisabledCircle {
  transform: translateX(0rem);
}

.secondaryEnabledCircle {
  transform: translateX(1rem);
}

.secondaryDisabledCircle {
  transform: translateX(0rem);
}

.circle {
  pointer-events: none;
  display: inline-block;
  height: 1rem;
  width: 1rem;
  border-radius: 1rem;
  box-shadow: 0px 12px 23px rgba(55, 125, 255, 0.06);
  background-color: $primary-white;

  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
}

.disabled {
  background-color: $secondary-four;
  cursor: not-allowed !important;
}

.leftLabel {
  display: flex;
}
